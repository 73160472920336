@import url(https://fonts.googleapis.com/css?family=Poppins);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Poppins';
  min-height: 100vh;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; 
}
.App {
  text-align: center;
  min-height: 100vh;
}

.nosafari{ 
	-webkit-appearance: none; 
	appearance: none;
  outline: none;

  background-color: rgb(30,30,30);
  font-size: 14px;
  padding-left: 0.5rem;
  text-indent: 5px; 
}

.animationShake{
  -webkit-animation: shake 0.5s;
          animation: shake 0.5s; 
  -webkit-animation-iteration-count: infinite; 
          animation-iteration-count: infinite; 
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.modal {
  outline: none;
  background: white;
  font-size: 1.6rem;
  width: 76rem;
  max-width: 50%;
  max-height: 200px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 15;
  min-height: 200px;
  border-radius: 12.5px;
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}


@media (max-width: 768px) {
  .modal {
      width: 76rem;
      max-width: 80%;
      min-height: 200px;
      max-height: 250px;
    margin-bottom: 0;
    border-radius: 12.5px;
  }
}

.modalLang {
  outline: none;
  background: white;
  font-size: 1.6rem;
  width: 250px;
  max-width: 35%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 15;
  min-height: 100px;
  border-radius: 12.5px;
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

@media (max-width: 768px) {
  .modalLang {
      width: 250px;
      max-width: 70%;
      min-height: 100px;
    margin-bottom: 0;
    border-radius: 12.5px;
  }
}

.modalPrefix {
  outline: none;
  background: white;
  font-size: 1.6rem;
  width: 310px;
  max-width: 35%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 15;
  min-height: 200px;
  border-radius: 12.5px;
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

@media (max-width: 768px) {
  .modalPrefix {
      width: 310px;
      max-width: 70%;
      min-height: 200px;
    margin-bottom: 0;
    border-radius: 12.5px;
  }
}


.modalInfo {
  outline: none;
  background: white;
  font-size: 1.6rem;
  width: 550px;
  max-width: 45%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 15;
  height: 450px;
  max-height: 80%;
  border-radius: 12.5px;
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

@media (max-width: 768px) {
  .modalInfo {
    width: 350px;
    max-width: 80%;
    height: 450px;
    max-height: 80%;
    margin-bottom: 0;
    border-radius: 12.5px;
  }
}
  
