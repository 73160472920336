.modal {
  outline: none;
  background: white;
  font-size: 1.6rem;
  width: 76rem;
  max-width: 50%;
  max-height: 200px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 15;
  min-height: 200px;
  border-radius: 12.5px;
  will-change: transform;
  transform: translate3d(0, 0, 0);
}


@media (max-width: 768px) {
  .modal {
      width: 76rem;
      max-width: 80%;
      min-height: 200px;
      max-height: 250px;
    margin-bottom: 0;
    border-radius: 12.5px;
  }
}

.modalLang {
  outline: none;
  background: white;
  font-size: 1.6rem;
  width: 250px;
  max-width: 35%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 15;
  min-height: 100px;
  border-radius: 12.5px;
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

@media (max-width: 768px) {
  .modalLang {
      width: 250px;
      max-width: 70%;
      min-height: 100px;
    margin-bottom: 0;
    border-radius: 12.5px;
  }
}

.modalPrefix {
  outline: none;
  background: white;
  font-size: 1.6rem;
  width: 310px;
  max-width: 35%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 15;
  min-height: 200px;
  border-radius: 12.5px;
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

@media (max-width: 768px) {
  .modalPrefix {
      width: 310px;
      max-width: 70%;
      min-height: 200px;
    margin-bottom: 0;
    border-radius: 12.5px;
  }
}


.modalInfo {
  outline: none;
  background: white;
  font-size: 1.6rem;
  width: 550px;
  max-width: 45%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 15;
  height: 450px;
  max-height: 80%;
  border-radius: 12.5px;
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

@media (max-width: 768px) {
  .modalInfo {
    width: 350px;
    max-width: 80%;
    height: 450px;
    max-height: 80%;
    margin-bottom: 0;
    border-radius: 12.5px;
  }
}
  